import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import AlternativeTo from "images/homepage/AlternativeTo";
import AndroidHeadlines from "images/homepage/AndroidHeadlines";
import AndroidPolice from "images/homepage/AndroidPolice";
import HackerNews from "images/homepage/HackerNews";
import PrivacyTools from "images/homepage/PrivacyTools";
import Reddit from "images/homepage/Reddit";
import WindScribbles from "images/homepage/Windscribbles";
import LeAlternative from "images/homepage/LeAlternative";
import ZeroClick from "images/homepage/ZeroClick";
import PrivacyGuides from "images/homepage/PrivacyGuides";
import LinuxTechTips from "images/homepage/LinusTechTips";

const Container = styled.section`
    overflow: hidden;
    white-space: nowrap;
    margin: 80px 0;
`;

const Content = styled.div`
    animation: wave 45s linear infinite;

    & > * {
        margin-right: 110px;
    }
`;

export default function MentionsTicker() {
    const [repeat, setRepeat] = useState(2);
    const ref = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (ref.current) {
            if (ref.current.scrollWidth < 2 * window.innerWidth) {
                setRepeat((prev) => prev + 1);
            }
        }
    }, [ref.current, repeat]);

    return (
        <Container>
            <Content ref={ref}>
                {Array(repeat)
                    .fill(2)
                    .map((_, index) => (
                        <React.Fragment key={index}>
                            <PrivacyGuides />
                            <HackerNews />
                            <LinuxTechTips />
                            <AlternativeTo />
                            <AndroidHeadlines />
                            <PrivacyTools />
                            <Reddit />
                            <AndroidPolice />
                            <WindScribbles />
                            {/* <LeAlternative /> */}
                            <ZeroClick />
                        </React.Fragment>
                    ))}
            </Content>
        </Container>
    );
}
