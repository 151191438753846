import React, { useEffect, useState } from "react";
import styled from "styled-components";

const DigitContainer = styled.div<{ selected: boolean }>`
    font-style: normal;
    font-weight: 800;
    color: #58bf43 !important;
    margin-bottom: 0;
    font-size: 50px;
    font-family: Gilroy;
    height: 70px;
    line-height: 70px;
    text-align: center;
    text-transform: capitalize;
    user-select: ${(props) => (props.selected ? "auto" : "none")};

    @media (min-width: 992px) {
        font-size: 80px;
    }

    @media (prefers-color-scheme: dark) {
        color: #ffffff !important;
    }
`;

interface IDigitsContainerProp {
    digit: string;
}

const DigitScroller = styled.div`
    overflow: hidden;
    max-height: 70px;
    margin-top: 50px;
`;

const availableDigits = [
    "0",
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "a",
    "b",
    "c",
    "d",
    "e",
    "f",
];

const Content = styled.div<{ digit: string }>`
    display: flex;
    flex-direction: column;
    transform: translateY(
        -${(props) => parseInt(props.digit, availableDigits.length) * 70}px
    );
    transition: transform 1s ease-out;
`;

const DigitsContainer = ({ digit }: IDigitsContainerProp) => {
    return (
        <DigitScroller>
            <Content digit={digit}>
                {availableDigits.map((item) => (
                    <DigitContainer key={item} selected={digit === item}>
                        {item}
                    </DigitContainer>
                ))}
            </Content>
        </DigitScroller>
    );
};

interface IProps {
    /** Decimal number to be shown in the counter */
    number?: string;
    /**
     * Base in which the counter should convert the number to.
     * Radix should be from 1 - 16.
     */
    radix?: number;
}

export default function Counter(props: IProps) {
    const { number, radix = 10 } = props;
    const [digits, setDigits] = useState<string[]>([]);

    useEffect(() => {
        if (number) {
            // reverse is required to place `,` correctly
            setDigits(
                parseInt(number)
                    .toString(radix > 0 && radix <= 16 ? radix : 10)
                    .split("")
                    .reverse(),
            );
        }
    }, [number, radix]);

    return (
        <div className="d-flex align-items-end justify-content-center justify-content-lg-end">
            {digits
                .reduce((prev: string[], digit, index) => {
                    if (index % 3 === 2 && index !== digits.length - 1) {
                        return [...prev, digit, ","];
                    }
                    return [...prev, digit];
                }, [])
                .reverse()
                .map((digit, index) =>
                    digit === "," ? (
                        <DigitContainer key={index} selected>
                            ,
                        </DigitContainer>
                    ) : (
                        <DigitsContainer key={index} digit={digit} />
                    ),
                )}
        </div>
    );
}
