export interface IReview {
    text: string;
    source:
        | "Apple User"
        | "Android User"
        | "HackerNews"
        | "Reddit"
        | "Community Member"
        | "Cryptographer";
    /* Present for HackerNews, Reddit and Community reviews */
    link?: string;
    who?: string;
}

const reviews: IReview[] = [
    {
        text: "Ente is really cool. End-to-end encrypted photo backup/sync (unlike Google Photos), truly multiplatform (unlike iCloud Photos), and fully open source (unlike both!)\n\nFull disclosure: @symbolicsoft was involved in a cryptographic audit of Ente.",
        source: "Cryptographer",
        who: "Nadim Kobeissi",
    },
    {
        text: "I love using this app. It's really smooth and has all the features I need, I started using ente because I wanted something secure (and usually that means you get fewer features), but that's not the case. With AI coming soon, I will probably never look back at any other photo management app. The developers are very open to feedback and respond very quickly if you have an issue.",
        source: "Android User",
        who: "Brogio",
    },
    {
        text: "Looked far and wide for a cloud photos app like this: zero-knowledge encryption, displays thumbnails quickly, handles “live” iPhone photos without separating them, respects HEIC format, retains original quality and EXIF metadata, allows for cross-platform syncing and family sharing. Happy to support an app that emphasizes privacy without sacrificing functionality.",
        source: "Apple User",
        who: "fishfighter22",
    },
    {
        text: "Been using Ente for over a year now and this continues to be a suitable Google Photos replacement for me and my family! It's still in need of some key features (automated compressed video previews for quick viewing and being the biggest one right now) , but the core is solid and it seems like the dev team is passionate about implementing features important to their users. Really happy with this app!",
        source: "Android User",
        who: "Sam Winger",
    },
    {
        text: "Customer service from Ente is nothing short of amazing. The team replies promptly and is always courteous. I had a small issue with the app and they pushed an update in under 10 hours. The issue was completely resolved. I’ve had subscriptions with all the major E2EE storage apps and they don’t come close in any aspect.",
        source: "Apple User",
        who: "Cream of melon",
    },
    {
        text: "I am really positively surprised. The app works flawlessly and makes managing pictures and videos across multiple platforms (mobile, tablet, laptop, and web) very easy. In addition, all data is end to end encrypted, which was crucial for me to start using the app. The prices are really fair and there is a great team behind Ente that is incredibly open to feature suggestions. It's a pity that I didn't come across Ente earlier. In summary, Ente is a great alternative to the big cloud providers and is not lagging behind them. At the same time, Ente makes it possible to store data securely and privately, without the hassle that normally comes with it.",
        source: "Apple User",
        who: "luk0411",
    },
    {
        text: "Excellent privacy friendly alternative to Google photos. Developer fixed a small bug witin 2 days!",
        source: "Android User",
        who: "Michiel van Drunen",
    },
    {
        text: "Best privacy-friendly replacement for Google photos I've come across so far. Beautiful interface, great map function and fair prices. Recommended!",
        source: "Android User",
        who: "Henning Lagemann",
    },
    {
        text: "Another superb app by team ente. It is a great alternative to the other photo storage apps currently available. It is a great looking app and it is easy on the eye. The functionality is top notch. It is E2EE for peace of mind. The fees are extremely competitive too. It was a little sluggish to use, but the latest update fixed that problem. Thank you! Highly recommended.",
        source: "Android User",
        who: "Jacques Vivier",
    },
    {
        text: "Great app for backing up photos. I love how easy it is to use and that it's more secure and private than google. It makes it really easy to manage photos and free up space on your phone knowing your photos are saved on its server.",
        source: "Android User",
        who: "Peter",
    },
    {
        text: "Ente is the perfect app for anyone looking to backup and organize their photos and videos securely. With end-to-end encryption and multiple storage locations, my memories are safe and accessible from any device. I also love the option to share albums with loved ones and the ability to free up space by removing safely backed-up files. The pricing is affordable and the human support is top-notch. Highly recommend!",
        source: "Android User",
        who: "V Keerthi Vikram",
    },
    {
        text: "Privacy-friendly alternative to Google Photos. Family plan at no extra cost! Good price, great app.",
        source: "Android User",
        who: "Daniel & Rie",
    },
    {
        text: "This is the best alternative to Google Photos currently available. The UI is really well designed and they are continually implementing new features with frequent updates",
        source: "Android User",
        who: "Peter Montague",
    },
    {
        text: "Ente has been a great way to share photos with a partner! Bonus it works on all platforms too!",
        source: "Android User",
        who: "Paul Hovey",
    },
    {
        text: "Interface ist sehr gewöhnungsbedürftig. Alles funktioniert aber zuverlässig. Preis ist sehr fair. Alles synchronisiert gut. Die Apps laufen auf allen Plattformen. Fotos und Videos sind alle Ende-zu-Ende verschlüsselt. Die App selber ist auch frei von Werbung und Trackern. Aktuell (Januar 2023) die beste private Alternative zu iCloud.",
        source: "Apple User",
        who: "Dariush K",
    },
    {
        text: "The best e2ee storage for photos and videos. A new star on the privacy Haven.",
        source: "Android User",
        who: "Patriii",
    },
    {
        text: "Hello developers, keep up the excellent work and never stop improving this app whatsoever It's become my primary photo gallery for my entire life The way you guys respect people's privacy is so appreciated 5 STAR with love ❤️❤️",
        source: "Apple User",
        who: "Ziyad Alahmadi",
    },
    {
        text: "This is just fantastic. There are a few missing features and rough edges but the developers are very active and responsive, so they will get sorted soon. In general, it's a well designed and reliable app that solved the problem of photo backup for someone who doesn't want to make compromises on privacy. So glad it exists",
        source: "Android User",
        who: "Leonardo Mazzone",
    },
    {
        text: "After trying 3 other encrypted photo storage options, I found ente far easier to use, photos are in the correct order, and the app itself is a thing of beauty! In my experience, ente is the most complete and offers the best experience overall. Encryption never looked so good!",
        source: "Android User",
        who: "David Keller",
    },
    {
        text: "Probably the best Google Photo replacement, with privacy in mind! Keep up the good work.",
        source: "Android User",
        who: "Robert Hedlund",
    },
    {
        text: "this is the best thing since sliced bread. On top of a very good app and a well thought out architecture you get a very friendly and super responsive team taking care of problems and feature requests. I wish I had known about this earlier.",
        source: "Apple User",
        who: "midshipmanorg",
    },
    {
        text: "One of the best google Photos alternative, It even has Family Plans 🤩. It also encrypts the metadata, while allowing to search through the metadata to find your photos.",
        source: "Community Member",
        link: "https://alternativeto.net/software/ente/about/#post-105436",
        who: "Vicky",
    },
    {
        text: "It works wonders. The developers are very quick to fix bugs when present and available in support. Photos are saved in maximum quality and synchronization is fast. The cost is really low (€10/year or €1/month for 10GB), and unlike Google Photo (free, meaning it makes money by selling our data), it is completely open-source, uses E2E encryption (while Google can easily see all the photos), and has a clear and clean privacy policy.",
        source: "Android User",
        who: "Pi",
    },
    {
        text: "Waiting & looking for this app for a long time. Thanks.",
        source: "Android User",
        who: "Kiarash Kay",
    },
    {
        text: "Best alternative to Google Photos",
        source: "Android User",
        who: "Quique Llaudet",
    },
    {
        text: "One of the best encrypted alternative to Google Photos.",
        source: "Android User",
        who: "Andrea Guani",
    },
    {
        text: "This is the Google Photos alternative I've been looking for.",
        source: "Android User",
        who: "Green",
    },
    {
        text: "Excellent, privacy oriented photo storage service.",
        source: "Android User",
        who: "Marcus “midshipman” Wille",
    },
    {
        text: "Love the product and the company. Future of internet",
        source: "Android User",
        who: "Sairee Chahal",
    },
    {
        text: "The best privacy alternative to Google Photos",
        source: "Community Member",
        link: "https://alternativeto.net/software/ente/about/#post-124728",
        who: "m00t316",
    },
    {
        text: "Very pretty, it surprised me",
        source: "Android User",
        who: "Pablo J",
    },
    {
        text: "Brilliant alternative to Google Photos, and it's encryption is top notch. Larger storage size options are really well priced too.. So glad I found this service. On a side note, one thing I'd love to see is a built in Exif cleaner option (something like Scrambled Exif). Thank you for this wonderful app, you have a very loyal customer here.",
        source: "Android User",
        who: "Ryan Simmons",
    },
    {
        text: "Open-source, actively maintaind, relatively cheap, E2E encrypted. Viable alternative to google photos, icloud photos and the like.",
        source: "Community Member",
        link: "https://alternativeto.net/software/ente/about/#post-115465",
        who: "aloofloofah",
    },
    {
        text: "Hellooo there! I am in awe!! You guys have done something that I've long wished for! Thank you so much for those cheap plans.",
        source: "Android User",
        who: "Mrinmay Dev Sarma",
    },
    {
        text: "Smooth experience with end to end encryption, can't find any better alternative to google photos than ente",
        source: "Android User",
        who: "Puneet Goyal",
    },
    {
        text: "can i rate more than 5 stars please?",
        source: "Android User",
        who: "大牛",
    },
    {
        text: "Best encrypted alternative to Google Photos.",
        source: "Community Member",
        link: "https://alternativeto.net/software/ente/about/#post-109682",
        who: "skariko",
    },
    {
        text: "Great app, have been using for a couple of months now. The 2fa and opensource code makes it more secure than using google photos.",
        source: "Android User",
        who: "Gautham Santhosh",
    },
    {
        text: "This is amazing. I can sense a feeling of 'why' begin this app and I believe that's much powerful to drive this forward.",
        source: "Android User",
        who: "Aravind Venugopal",
    },
    {
        text: "Fast response from the devs fixed an issue. Great job!",
        source: "Android User",
        who: "Peter Engholm",
    },
    {
        text: "Absolutely beautiful and well working app, everything I need from a photo app, not from google and OSS! love this, keep up the great work, team!",
        source: "Android User",
        who: "kataklasm",
    },
    {
        text: "The best Google Photos alternative that I could find that actually respects your privacy. Absolutely love their product. While there are other privacy focused GP alternatives out there, Ente actually tries (and increasingly succeeds) to make something that is just as user friendly and feature packed.",
        source: "Community Member",
        link: "https://alternativeto.net/software/ente/about/#post-105728",
        who: "Laurens",
    },
    {
        text: "Have been on the lookout for a privacy friendly photo sync and backup solution and ente delivers. Now I feel my photos are truly safe.",
        source: "Android User",
        who: "Chaitanya Pramod",
    },
    {
        text: "This is probably the only, and best cloud photos option out there. Secure, private, open source, reliable, and beautiful UI. Obviously it's not perfect, it has SOME issues, but overall it's worth it!",
        source: "Apple User",
        who: "Sowers25",
    },
    {
        text: "Such a great project :) It looks and feels so intuitive out of the box, wonderful!",
        source: "Reddit",
        link: "https://www.reddit.com/r/enteio/comments/njg42s/hello_world/gze6h8r/",
        who: "u/bendavidi",
    },
    {
        text: "Take my money!!!",
        source: "HackerNews",
        who: "istingray",
        link: "https://news.ycombinator.com/item?id=28351288",
    },
    {
        text: "Thank God, something awesome like this finally exists. Thank youuuu to the devs of ente!",
        source: "Reddit",
        link: "https://www.reddit.com/r/degoogle/comments/njatok/comment/gz9nte9/",
        who: "u/coolbeb",
    },
    {
        text: "Ente is now my favorite :)",
        source: "Reddit",
        link: "https://www.reddit.com/r/enteio/comments/njg42s/comment/h5i641s/",
        who: "u/bendavidi",
    },
    {
        text: "Totally Awesome, great work and thanks!!",
        source: "Reddit",
        link: "https://www.reddit.com/r/degoogle/comments/njatok/comment/gz8y5gm/",
        who: "u/C4nn4Cat",
    },
    {
        text: "I really love your product",
        source: "HackerNews",
        who: "Rume",
        link: "https://news.ycombinator.com/item?id=28350311",
    },
    {
        text: "I like ente, I have been using it instead of google photos for almost a year and I have never regret it. A few euros yearly for a privacy-respecting alternative that does what it is supposed to do smoothly and simply is a big yes from me.",
        source: "Community Member",
        link: "https://alternativeto.net/software/ente/about/#post-105434",
        who: "Pietro Smusi",
    },
    {
        text: "they don't market enough as they probably should but it's a great cross-platform google photos alternative. the devs are very active too.",
        source: "Community Member",
        link: "https://alternativeto.net/software/ente/about/#post-104064",
        who: "p0xxy",
    },
    {
        text: "Would like to recommend ente to anyone trying to degoogle...automatic end-to-end encrypted backups of your photos and  videos, in their original quality.",
        source: "Community Member",
        link: "https://fosstodon.org/@naturallytony/108214697588297831",
        who: "Tony",
    },
    {
        text: "Siento que mis fotos están seguras en Ente. La privacidad es importante para mi. La app es muy intuitiva y fácil de usar. La recomiendo al 100%.",
        source: "Apple User",
        who: "RavalMatic",
    },
];

export default reviews;
