import React from "react";
import { ISVGProps } from "types";

export default function LinuxTechTips(props: ISVGProps) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 360 360"
            width="42"
            height="42"
        >
            <path
                fill="#727272"
                d="M356.81 146.42c-2.953-15.584-7.877-30.478-14.58-44.37-7.088-14.694-16.152-28.252-26.785-40.404-12.103-13.835-26.251-25.834-42.02-35.45C246.174 9.582 214.185 0 180 0c-24.834 0-48.508 5.056-70.05 14.191-17.081 7.242-32.79 17.085-46.7 28.973C24.582 76.205 0 125.272 0 180s24.583 103.8 63.25 136.83c6.88 5.88 14.194 11.254 21.908 16.058 27.561 17.158 60.056 27.107 94.843 27.107 24.835 0 48.51-5.056 70.051-14.192 17.08-7.243 32.79-17.085 46.7-28.973 38.667-33.04 63.25-82.106 63.25-136.83 0-11.478-1.126-22.694-3.19-33.58zM63.25 294.35C34.373 264.872 16.548 224.527 16.548 180S34.373 95.13 63.25 65.65v228.7zm186.8-113.24v146.6c-21.234 10.09-44.98 15.751-70.051 15.751-26.456 0-51.422-6.324-73.531-17.484l131.91-42.861v-49.103l-128.43 41.73V32.302c21.233-10.088 44.978-15.75 70.05-15.75 25.803 0 50.197 6 71.897 16.647l-130.27 42.33v49.103l35.023-11.382v135.04l46.7-15.178V98.081l93.4-30.346 1.51-.49c11.245 11.79 20.748 25.246 28.09 40l-111.32 36.166v49.105zm46.7 113.24V165.93l44.188-14.356c1.62 9.237 2.514 18.724 2.514 28.424 0 44.526-17.825 84.871-46.702 114.35z"
            />
        </svg>
    );
}
