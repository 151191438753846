import reviews from "data/reviews";
import NextBtn from "images/homepage/NextBtn";
import PrevBtn from "images/homepage/PrevBtn";
import React, { useState } from "react";
import styled from "styled-components";

const Wrapper = styled.div`
    position: relative;
    overflow: hidden;
    width: 540px;
    max-width: 100%;
    margin: 0 auto;
`;

const Container = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    overflow: hidden;
    flex: 1;
    width: 540px;
    min-height: 420px;
    position: relative;
`;

const Shadow = styled.div`
    position: absolute;
    left: 24px;
    bottom: 33px;
    top: 75px;
    width: 310px;
    border-radius: 86px;
    opacity: 0.8;
    background: linear-gradient(
        189deg,
        #b7b7b7 9.02%,
        rgba(100, 100, 100, 0) 106.22%
    );
    filter: blur(41px);
    z-index: 1;
    pointer-events: none;
`;

const Card = styled.div<{ active?: boolean }>`
    padding: 32px;
    border-radius: 30px;
    font-size: 20px;
    line-height: 30px;
    display: flex;
    flex-direction: column;
    width: 300px;
    min-height: 386px;
    max-height: 386px;
    position: absolute;
    transition:
        transform 0.25s ease-out,
        left 0.25s ease-out;

    color: #1d1d1f;
    z-index: ${(props) => (props.active ? "2" : "0")};
    margin-left: ${(props) => (props.active ? "0" : "-40px")};
    background: #f4f4f4;

    &:nth-child(even) {
        background: #58bf43;
        color: #ffffff;
    }
`;

const Reviewer = styled.div`
    font-size: 16px;
`;

const ReviewerPlatform = styled.div`
    color: #1d1d1f;
    opacity: 0.5;
`;

const ReviewerContainer = styled.div`
    margin-top: 32px;
    font-size: 12px;
    line-height: 170%;
    font-weight: 700;
`;

const NavButtonContainer = styled.div`
    display: flex;
    width: 150px;
    gap: 45px;
    justify-content: space-between;
    margin: 0 auto;

    @media (min-width: 992px) {
        position: absolute;
        right: 18px;
        bottom: 4px;
    }
`;

const NavButton = styled.button`
    all: unset;
    margin: 4px;
    color: #1db954;
    border-radius: 50%;
    transition: box-shadow 0.1s; // Add transition for smooth effect

    &:disabled {
        color: #c0c0c0;
    }

    &:hover,
    &:focus-within {
        box-shadow: 0 0 8px var(--color-primary-highlight-light); // Add shadow effect
    }
`;

const TextWrapper = styled.div<{ green?: boolean }>`
    position: relative;
    display: flex;
    overflow: hidden;
    flex: 1;

    &:after {
        content: " ";
        position: absolute;
        pointer-events: none;
        width: 100%;
        bottom: 0;
        top: 50%;
        left: 0;
        background: linear-gradient(
            180deg,
            transparent 50%,
            ${(props) => (props.green ? "#58BF43" : "#f4f4f4")}
        );
    }
`;

const Text = styled.pre`
    flex: 1;
    overflow-y: scroll;
    padding-bottom: 50px;
    white-space: break-spaces;
    font-family: var(--bs-body-font-family);
    font-size: 20px;

    /* Always show scrollbar for Webkit browsers */
    &::-webkit-scrollbar {
        -webkit-appearance: none;
        width: 5px;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 4px;
        background-color: rgba(0, 0, 0, 0.08);
        box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
    }

    /* Always show scrollbar for Firefox */
    scrollbar-width: thin;
    scrollbar-color: rgba(0, 0, 0, 0.2) transparent;

    /* Always show scrollbar for IE and Edge */
    -ms-overflow-style: scrollbar;
`;

const total = reviews.length;

export default function ReviewsCarousel() {
    const [active, setActive] = useState(0);
    const [touchStart, setTouchStart] = useState(null);
    const [touchEnd, setTouchEnd] = useState(null);

    // the required distance between touchStart and touchEnd to be detected as a swipe
    const minSwipeDistance = 50;

    const onTouchStart = (e: any) => {
        setTouchEnd(null); // otherwise the swipe is fired even with usual touch events
        setTouchStart(e.targetTouches[0].clientX);
    };

    const onTouchMove = (e: any) => setTouchEnd(e.targetTouches[0].clientX);

    const onTouchEnd = () => {
        if (!touchStart || !touchEnd) {
            return;
        }
        const distance = touchStart - touchEnd;
        const isLeftSwipe = distance > minSwipeDistance;
        const isRightSwipe = distance < -minSwipeDistance;
        if (isLeftSwipe) {
            next();
        } else if (isRightSwipe) {
            prev();
        }
    };

    const next = () => {
        if (active === total - 1) {
            return;
        }
        setActive((prev) => prev + 1);
    };

    const prev = () => {
        if (active === 0) {
            return;
        }
        setActive((prev) => prev - 1);
    };

    const getPosition = (index: number, active: number) => {
        if (index < active) {
            return "-300px";
        } else if (index === active) {
            return "0px";
        } else if (index === active + 1) {
            return "300px";
        } else {
            return "550px";
        }
    };

    const moveTo = (index: number) => () => setActive(index);

    return (
        <Wrapper>
            <Container
                onTouchStart={onTouchStart}
                onTouchMove={onTouchMove}
                onTouchEnd={onTouchEnd}
            >
                <Shadow />
                {reviews.map((review, index) => (
                    <Card
                        key={index}
                        active={active === index}
                        onClick={moveTo(index)}
                        style={{
                            left: getPosition(index, active),
                            transform: `scale(${
                                index <= active ? "1" : "0.8"
                            })`,
                        }}
                    >
                        <TextWrapper green={index % 2 == 0}>
                            <Text>{review.text}</Text>
                        </TextWrapper>
                        <ReviewerContainer>
                            <Reviewer>{review.who}</Reviewer>
                            <ReviewerPlatform>{review.source}</ReviewerPlatform>
                        </ReviewerContainer>
                    </Card>
                ))}
            </Container>
            <NavButtonContainer>
                <NavButton
                    onClick={prev}
                    disabled={active === 0}
                    aria-label="Previous Testimonial"
                >
                    <PrevBtn />
                </NavButton>
                <NavButton
                    onClick={next}
                    disabled={active === total - 1}
                    aria-label="Next Testimonial"
                >
                    <NextBtn />
                </NavButton>
            </NavButtonContainer>
        </Wrapper>
    );
}
