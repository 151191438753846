import React from "react";
import { ISVGProps } from "types";

export default function PrevBtn(props: ISVGProps) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="53"
            height="53"
            viewBox="0 0 53 53"
            fill="none"
        >
            <path
                d="M26.5 0C11.8841 0 0 11.8242 0 26.3664C0 40.9087 11.8841 52.7328 26.5 52.7328C41.1159 52.7328 53 40.9087 53 26.3664C53 11.8242 41.1159 0 26.5 0ZM26.5 3.2958C39.3257 3.2958 49.6875 13.6053 49.6875 26.3664C49.6875 39.1275 39.3257 49.437 26.5 49.437C13.6743 49.437 3.3125 39.1275 3.3125 26.3664C3.3125 13.6053 13.6743 3.2958 26.5 3.2958ZM29.7866 15.6293C29.354 15.6463 28.9453 15.8312 28.6479 16.1443L19.5386 25.2077C19.232 25.5162 19.0601 25.9326 19.0601 26.3664C19.0601 26.8002 19.232 27.2166 19.5386 27.5251L28.6479 36.5885C28.7938 36.7686 28.9761 36.9162 29.1831 37.0215C29.39 37.1269 29.6169 37.1878 29.849 37.2001C30.0812 37.2125 30.3133 37.1762 30.5304 37.0934C30.7475 37.0106 30.9446 36.8833 31.109 36.7198C31.2733 36.5563 31.4013 36.3601 31.4845 36.1441C31.5676 35.9282 31.6042 35.6972 31.5918 35.4662C31.5793 35.2353 31.5181 35.0095 31.4122 34.8036C31.3063 34.5977 31.1581 34.4163 30.9771 34.2712L23.0322 26.3664L30.9771 18.4616C31.2166 18.2311 31.3808 17.934 31.448 17.6092C31.5152 17.2844 31.4823 16.947 31.3538 16.6411C31.2252 16.3351 31.0068 16.0749 30.7272 15.8944C30.4476 15.7139 30.1198 15.6215 29.7866 15.6293Z"
                fill="currentcolor"
            />
        </svg>
    );
}
