import FAQ from "images/homepage/FAQ";
import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import FAQCard from "./FAQCard";
import { Link } from "gatsby";
import SeeAllButton from "components/buttons/SeeAllButton";

const Container = styled.section.attrs({
    className: "container",
})`
    margin-top: 150px;
`;

const Heading = styled.h1`
    font-style: normal;
    font-weight: 800;
    line-height: 100%;
    text-align: left;
    margin-top: -80px;
    margin-left: 130px;
    font-size: 72px;
    margin-bottom: 65px;

    @media (max-width: 576px) {
        margin-left: 0;
        text-align: center;
        margin-top: -160px;
    }
`;

const FAQIcon = styled(FAQ)`
    @media (max-width: 576px) {
        width: 120px;
    }
`;

const FAQsArr = [
    {
        question: <>How does Ente ensure the security of my photos?</>,
        answer: (
            <>
                Ente is end-to-end encrypted. Our open-source apps have been{" "}
                <a href="/blog/cryptography-audit">
                    externally audited by expert cryptographers
                </a>{" "}
                who have certified that your photos are only visible to you. In
                addition to this, we store 3 copies of your data, in 3 different
                locations – Amsterdam, Frankfurt and Paris. The data center in
                Paris is in a fallout shelter 25m under the ground. The one in
                Frankfurt uses compliance locks to stay immune to ransomware
                attacks. All of these systems are regularly audited.
            </>
        ),
    },
    {
        question: <>Can I transfer my photos from Google or Apple?</>,
        answer: (
            <>
                Yes! We have a simple migration process from both{" "}
                <a href="/faq/migration/from-google-photos/">Google</a> and{" "}
                <a href="/faq/migration/from-apple-photos/">Apple</a>. Just
                takeout your data, and drop it into our{" "}
                <a href="/download/desktop">Desktop app</a> and you are set!
            </>
        ),
    },
    {
        question: <>Does Ente have an app for my device?</>,
        answer: (
            <>
                Ente has apps for Android, iOS, Web, Mac, Linux and Windows. All
                of these apps are open-source, and automatically backup your
                photos in the background.
                <br />
                We also have a{" "}
                <a href="https://github.com/ente-io/ente/tree/main/cli#readme">
                    Command Line Interface
                </a>{" "}
                for those who wish to run Ente on their servers.
            </>
        ),
    },
    {
        question: <>How can I share my photos with end-to-end encryption?</>,
        answer: (
            <>
                If your friend is on Ente, you can add them as a viewer or a
                collaborator to your album using any of our apps. They can view
                your photos, and even add their own photos to your albums,
                end-to-end encrypted.
                <br />
                <br />
                If your friend is not on Ente, you can share a link to your
                album with them. They can view your photos on a browser, without
                installing the app or needing an account. You can also allow
                them to add photos via these links. Even these uploads are
                end-to-end encrypted.
            </>
        ),
    },
    {
        question: <>Can I share my subscription with family and friends?</>,
        answer: (
            <>
                You can add upto 5 family members and share your available
                storage space with them at no extra cost. Each member will get
                their own private space, and can only access their own photos.
                <br />
                <br />
                We also have a Referral Program, where you can get 10 GB of free
                storage for every paid customer you refer. You can share your
                referral code with your friends, and they will receive an extra
                10 GB as well.
            </>
        ),
    },
    {
        question: <>What does "Ente" mean?</>,
        answer: (
            <>
                "Ente" (pronounced "entay") means "mine" in Malayalam. "Ente"
                also means a "duck" in German. Hence the{" "}
                <a href="/blog/ducky">little Ducky</a> - an evangelist of
                privacy, making it easy to own our memories! 🐣
            </>
        ),
    },
];

export default function FAQs() {
    const [active, setActive] = useState(-1);

    return (
        <Container>
            <FAQIcon />
            <Heading>FAQs</Heading>
            {FAQsArr.map((faq, index) => (
                <FAQCard
                    key={index}
                    question={faq.question}
                    answer={faq.answer}
                    onClick={() => setActive(active === index ? -1 : index)}
                    expanded={active === index}
                />
            ))}
            <div className="d-flex justify-content-center">
                <Link to="/faq" tabIndex={-1}>
                    <SeeAllButton>See All FAQs</SeeAllButton>
                </Link>
            </div>
        </Container>
    );
}
