import React from "react";
import { ISVGProps } from "types";

export default function HeadingHighlight(props: ISVGProps) {
    return (
        <svg
            width="920"
            height="242"
            viewBox="0 0 920 242"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <rect
                x="7.61657"
                y="74.5288"
                width="913"
                height="90"
                rx="45"
                transform="rotate(4.85466 7.61657 74.5288)"
                fill="var(--color-primary-highlight)"
            />
            <rect
                x="2.17126"
                y="82.5347"
                width="913"
                height="90"
                rx="45"
                transform="rotate(-5.18659 2.17126 82.5347)"
                fill="var(--color-primary-highlight)"
            />
        </svg>
    );
}
