import React from "react";
import styled from "styled-components";
import CustomerReview from "images/homepage/CustomerReview";
import ReviewsCarousel from "./ReviewsCarousel";

const Container = styled.section.attrs({
    className: "container",
})`
    margin-top: 150px;
`;

export default function CustomerReviews() {
    return (
        <Container>
            <div className="row d-flex align-items-center">
                <div className="col-lg-5 col-md-12 text-center">
                    <CustomerReview width="90%" />
                </div>
                <div className="col-lg-7 col-md-12 d-flex flex-column pe-0">
                    <ReviewsCarousel />
                </div>
            </div>
        </Container>
    );
}
