import SeeAllButton from "components/buttons/SeeAllButton";
import { Link } from "gatsby";
import HeadingHighlight from "images/homepage/HeadingHighlight";
import React from "react";
import styled from "styled-components";
import { FluidImageData } from "types";

const Container = styled.section.attrs({
    className: "container text-center",
})`
    padding-top: 1px;
`;

const Heading = styled.h1`
    color: var(--bs-code-color);
    font-weight: bold;
    max-width: 932px;
    margin: 200px auto 20px auto;
    padding: 20px 0px;
    position: relative;

    @media (min-width: 992px) {
        font-size: 56px;
        padding: 15px 0px;
    }
`;

const Highlight = styled(HeadingHighlight)`
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    max-height: 100%;
    max-width: 100%;
    z-index: -1;
`;

const SubHeading = styled.p`
    font-size: 20px;
    max-width: 550px;
    margin: 0 auto;
    font-weight: 400;
`;

interface IProps {
    images: FluidImageData[];
}

const Picture = styled.picture.attrs({ loading: "lazy" })<{
    aspectRatio: { light: string; dark: string };
}>`
    position: relative;
    overflow: hidden;
    margin-bottom: -100px;
    z-index: -1;

    & > img {
        max-width: 100%;
        width: 416px;
        object-fit: contain;
        aspect-ratio: ${(props) => props.aspectRatio.light} / 1;
        margin-bottom: -50px;
    }

    &:after {
        content: " ";
        position: absolute;
        top: 60%;
        left: 0;
        bottom: 0;
        width: 100%;
        background: linear-gradient(180deg, transparent, var(--bs-body-bg) 70%);
    }

    @media (prefers-color-scheme: dark) {
        & > img {
            aspect-ratio: ${(props) => props.aspectRatio.dark} / 1;
        }
    }
`;

const FeatureContainerLeft = styled.div.attrs({
    className: "row d-flex justify-content-center mt-5",
})`
    margin-bottom: 150px;
`;

const FeatureContainerRight = styled.div.attrs({
    className:
        "row d-flex justify-content-center mt-5 flex-md-row flex-column-reverse",
})`
    margin-bottom: 150px;
`;

export default function Features(props: IProps) {
    return (
        <Container id="features">
            <Heading>
                <Highlight />
                The best place to preserve your perfect photo collection
            </Heading>
            <SubHeading>
                A sharp focus on security and privacy, combined with all of the
                features you expect.
            </SubHeading>
            <FeatureContainerLeft>
                <div className="col-md-4 d-flex justify-content-md-start justify-content-center">
                    <Picture
                        aspectRatio={{
                            light: props.images[0].fluid.aspectRatio,
                            dark: props.images[4].fluid.aspectRatio,
                        }}
                    >
                        <source
                            srcSet={props.images[4].fluid.srcSetWebp}
                            media="(prefers-color-scheme: dark)"
                        />
                        <img
                            srcSet={props.images[0].fluid.srcSetWebp}
                            loading="lazy"
                            alt="Rediscover your memories"
                        />
                    </Picture>
                </div>
                <div className="col-md-4 d-flex justify-content-center flex-column text-md-start text-sm-center">
                    <h2 className="text-md-start text-sm-center">
                        <strong>Rediscover your memories</strong>
                    </h2>
                    <p className="mt-0">
                        Easily relive your best moments, with complete privacy.
                    </p>
                    <p className="mt-0">
                        Search powered by on-device machine learning keeps your
                        data and search history end-to-end encrypted.
                    </p>
                </div>
            </FeatureContainerLeft>
            <FeatureContainerRight>
                <div className="col-md-4 d-flex justify-content-center flex-column text-md-start text-sm-center">
                    <h2 className="text-md-start text-sm-center">
                        <strong>Share and collaborate</strong>
                    </h2>
                    <p className="mt-0">Privately share your albums</p>
                    <p className="mt-0">
                        Collect photos and collaborate with your friends.
                        Everything end-to-end encrypted.
                    </p>
                </div>
                <div className="col-md-4 d-flex justify-content-md-end justify-content-center">
                    <Picture
                        aspectRatio={{
                            light: props.images[1].fluid.aspectRatio,
                            dark: props.images[5].fluid.aspectRatio,
                        }}
                    >
                        <source
                            srcSet={props.images[5].fluid.srcSetWebp}
                            media="(prefers-color-scheme: dark)"
                        />
                        <img
                            srcSet={props.images[1].fluid.srcSetWebp}
                            loading="lazy"
                            alt="Share and collaborate"
                        />
                    </Picture>
                </div>
            </FeatureContainerRight>
            <FeatureContainerLeft>
                <div className="col-md-4 d-flex justify-content-md-start justify-content-center">
                    <Picture
                        aspectRatio={{
                            light: props.images[2].fluid.aspectRatio,
                            dark: props.images[6].fluid.aspectRatio,
                        }}
                    >
                        <source
                            srcSet={props.images[6].fluid.srcSetWebp}
                            media="(prefers-color-scheme: dark)"
                        />
                        <img
                            srcSet={props.images[2].fluid.srcSetWebp}
                            loading="lazy"
                            alt="Organize and protect"
                        />
                    </Picture>
                </div>
                <div className="col-md-4 d-flex justify-content-center flex-column text-md-start text-sm-center">
                    <h2 className="text-md-start text-sm-center">
                        <strong>Organize and protect</strong>
                    </h2>
                    <p className="mt-0">
                        Favorite your best clicks, archive the noisy ones and
                        lock those special moments away from prying eyes.
                    </p>
                </div>
            </FeatureContainerLeft>
            <FeatureContainerRight>
                <div className="col-md-4 d-flex justify-content-center flex-column text-md-start text-sm-center">
                    <h2 className="text-md-start text-sm-center">
                        <strong>Together with family</strong>
                    </h2>
                    <p className="mt-0">
                        Share your subscription with your family, for free.
                    </p>
                    <p className="mt-0">
                        Sync specific albums and your entire library with your partner.
                    </p>
                </div>
                <div className="col-md-4 d-flex justify-content-md-end justify-content-center">
                    <Picture
                        aspectRatio={{
                            light: props.images[3].fluid.aspectRatio,
                            dark: props.images[7].fluid.aspectRatio,
                        }}
                    >
                        <source
                            srcSet={props.images[7].fluid.srcSetWebp}
                            media="(prefers-color-scheme: dark)"
                        />
                        <img
                            srcSet={props.images[3].fluid.srcSetWebp}
                            loading="lazy"
                            alt="Together with family"
                        />
                    </Picture>
                </div>
            </FeatureContainerRight>
            <div className="d-flex justify-content-center">
                <Link to="/features" tabIndex={-1}>
                    <SeeAllButton>See All Features</SeeAllButton>
                </Link>
            </div>
        </Container>
    );
}
