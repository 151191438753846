import React from "react";
import { ISVGProps } from "types";

export default function PrivacyTools(props: ISVGProps) {
    return (
        <svg
            width="228"
            height="46"
            viewBox="0 0 228 46"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g opacity="0.5">
                <path
                    d="M127.042 0.769531C124.824 0.87709 113.382 4.58339 108.108 7.44267C107.35 7.85497 106.517 9.26669 106.512 9.88515C106.512 15.5589 107.879 23.9261 111.738 31.3611L119.285 23.814C117.828 20.1257 118.698 15.9219 121.499 13.1209C123.206 11.4985 126.415 10.2975 129.207 10.2482C130.475 10.2258 131.659 10.4409 132.573 10.9607C133.106 11.27 132.855 11.6688 132.694 11.8302L126.912 17.3739C126.182 18.1582 126.814 20.1705 127.289 21.4522C128.566 21.9228 130.578 22.5547 131.363 21.8242L136.911 16.0429C137.068 15.886 137.467 15.6351 137.776 16.1684C139.439 19.0904 137.978 24.7596 135.62 27.238C132.815 30.0435 128.611 30.9129 124.927 29.4519L116.264 38.1149C119.079 41.3775 122.624 44.1023 127.042 45.8277C143.019 39.5938 147.573 20.2467 147.573 9.88515C147.568 9.26669 146.739 7.85497 145.977 7.44267C140.702 4.58339 129.261 0.87709 127.042 0.769531Z"
                    fill="#A6A6A6"
                />
                <path
                    d="M153.256 14.9095V11.7993H173.212V14.9095H164.989V35.2023H161.48V14.9095H153.256Z"
                    fill="currentcolor"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M170.299 23.2317C170.77 22.1382 171.402 21.2015 172.204 20.4217C173.006 19.6195 173.943 18.9966 175.014 18.5484C176.107 18.0823 177.264 17.8493 178.492 17.8493C179.715 17.8493 180.863 18.0823 181.934 18.5484C183.027 18.9966 183.964 19.6195 184.744 20.4217C185.546 21.2015 186.169 22.1382 186.612 23.2317C187.083 24.2983 187.316 25.4456 187.316 26.6736C187.316 27.9195 187.083 29.0936 186.612 30.1827C186.169 31.2538 185.546 32.1905 184.744 32.9927C183.964 33.7725 183.027 34.3954 181.934 34.866C180.863 35.3097 179.715 35.5337 178.492 35.5337C177.264 35.5337 176.107 35.3097 175.014 34.866C173.943 34.3954 173.006 33.7725 172.204 32.9927C171.402 32.1905 170.77 31.2538 170.299 30.1827C169.856 29.0936 169.632 27.9195 169.632 26.6736C169.632 25.4456 169.856 24.2983 170.299 23.2317ZM182.4 30.8863C183.493 29.7256 184.04 28.3228 184.04 26.6736C184.04 25.0243 183.493 23.6306 182.4 22.4967C181.333 21.3584 180.029 20.7892 178.492 20.7892C176.955 20.7892 175.637 21.3584 174.543 22.4967C173.454 23.6306 172.908 25.0243 172.908 26.6736C172.908 28.3228 173.441 29.7256 174.512 30.8863C175.606 32.0201 176.932 32.5893 178.492 32.5893C180.029 32.5893 181.333 32.0201 182.4 30.8863Z"
                    fill="currentcolor"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M189.176 23.2317C189.647 22.1382 190.279 21.2015 191.081 20.4217C191.883 19.6195 192.82 18.9966 193.891 18.5484C194.984 18.0823 196.14 17.8493 197.368 17.8493C198.592 17.8493 199.739 18.0823 200.81 18.5484C201.904 18.9966 202.841 19.6195 203.62 20.4217C204.423 21.2015 205.045 22.1382 205.489 23.2317C205.96 24.2983 206.193 25.4456 206.193 26.6736C206.193 27.9195 205.96 29.0936 205.489 30.1827C205.045 31.2538 204.423 32.1905 203.62 32.9927C202.841 33.7725 201.904 34.3954 200.81 34.866C199.739 35.3097 198.592 35.5337 197.368 35.5337C196.14 35.5337 194.984 35.3097 193.891 34.866C192.82 34.3954 191.883 33.7725 191.081 32.9927C190.279 32.1905 189.647 31.2538 189.176 30.1827C188.732 29.0936 188.508 27.9195 188.508 26.6736C188.508 25.4456 188.732 24.2983 189.176 23.2317ZM201.276 30.8863C202.37 29.7256 202.917 28.3228 202.917 26.6736C202.917 25.0243 202.37 23.6306 201.276 22.4967C200.21 21.3584 198.906 20.7892 197.368 20.7892C195.831 20.7892 194.514 21.3584 193.42 22.4967C192.331 23.6306 191.784 25.0243 191.784 26.6736C191.784 28.3228 192.318 29.7256 193.389 30.8863C194.482 32.0201 195.809 32.5893 197.368 32.5893C198.906 32.5893 200.21 32.0201 201.276 30.8863Z"
                    fill="currentcolor"
                />
                <path
                    d="M211.427 11.0641V35.2021H208.084V11.7991L211.427 11.0641Z"
                    fill="currentcolor"
                />
                <path
                    d="M212.494 32.9928L214.3 30.7878C215.192 31.478 216.093 32.0113 217.007 32.3922C217.944 32.7463 218.889 32.9256 219.848 32.9256C221.054 32.9256 222.031 32.6925 222.788 32.2219C223.55 31.7558 223.927 31.1419 223.927 30.3845C223.927 29.7839 223.702 29.3044 223.259 28.9459C222.811 28.5918 222.12 28.3453 221.184 28.2109L218.109 27.7762C216.438 27.5342 215.169 27.0188 214.3 26.239C213.453 25.4368 213.027 24.3791 213.027 23.066C213.027 21.5243 213.641 20.2874 214.869 19.3552C216.093 18.3961 217.697 17.9166 219.682 17.9166C220.928 17.9166 222.111 18.0959 223.223 18.4499C224.361 18.8084 225.464 19.3552 226.535 20.0902L224.827 22.2951C223.895 21.6722 222.981 21.224 222.089 20.9596C221.22 20.6683 220.337 20.5249 219.445 20.5249C218.423 20.5249 217.598 20.7355 216.976 21.1568C216.348 21.5826 216.039 22.1383 216.039 22.8285C216.039 23.4559 216.25 23.9354 216.671 24.2671C217.119 24.5808 217.84 24.8138 218.844 24.9707L221.919 25.4054C223.59 25.6474 224.863 26.1717 225.733 26.974C226.624 27.7762 227.068 28.8383 227.068 30.1514C227.068 30.9088 226.88 31.6214 226.499 32.2892C226.145 32.939 225.643 33.4947 224.998 33.9608C224.375 34.4314 223.626 34.8078 222.757 35.0991C221.887 35.368 220.951 35.498 219.947 35.498C218.477 35.498 217.097 35.2874 215.801 34.8661C214.533 34.4403 213.431 33.8174 212.494 32.9928Z"
                    fill="currentcolor"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M0.0559082 35.1975V11.7945H10.888C13.1602 11.7945 14.9976 12.4533 16.4049 13.7709C17.83 15.0616 18.5426 16.7422 18.5426 18.8172C18.5426 20.8698 17.83 22.537 16.4049 23.8322C14.9752 25.1229 13.1378 25.7727 10.888 25.7727H3.56502V35.1975H0.0559082ZM10.5205 14.8734H3.56502V22.7969H10.5205C11.8784 22.7969 12.9585 22.4384 13.7607 21.7258C14.5853 20.9908 14.9976 20.0183 14.9976 18.8172C14.9976 17.6117 14.5853 16.6571 13.7607 15.94C12.9585 15.2274 11.8784 14.8734 10.5205 14.8734Z"
                    fill="currentcolor"
                />
                <path
                    d="M20.4249 35.1977V18.1809H23.7682V20.3545C24.2791 19.5299 24.9379 18.907 25.7401 18.4812C26.5423 18.0375 27.4431 17.8134 28.447 17.8134C28.8055 17.8134 29.1148 17.8359 29.3837 17.8807C29.6526 17.9255 29.908 17.9927 30.1545 18.0823V21.0895C29.8408 20.9775 29.5181 20.8878 29.182 20.8206C28.8504 20.7579 28.5142 20.722 28.1826 20.722C27.2011 20.722 26.3183 20.9909 25.5385 21.5242C24.7811 22.0396 24.1895 22.806 23.7682 23.8323V35.1977H20.4249Z"
                    fill="currentcolor"
                />
                <path
                    d="M33.1975 15.4426C32.6418 15.4426 32.1623 15.2409 31.7589 14.8421C31.3601 14.4163 31.1584 13.9278 31.1584 13.3721C31.1584 12.8119 31.3601 12.3324 31.7589 11.9335C32.1623 11.5077 32.6418 11.2971 33.1975 11.2971C33.7532 11.2971 34.2328 11.5077 34.6361 11.9335C35.0574 12.3324 35.268 12.8119 35.268 13.3721C35.268 13.9278 35.0574 14.4163 34.6361 14.8421C34.2328 15.2409 33.7532 15.4426 33.1975 15.4426ZM34.8692 18.1854V35.1976H31.5259V18.1854H34.8692Z"
                    fill="currentcolor"
                />
                <path
                    d="M43.0211 35.1976L35.6668 18.1809H39.3417L44.6569 30.9177L49.9721 18.1809H53.5529L46.1941 35.1976H43.0211Z"
                    fill="currentcolor"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M59.7063 35.5023C57.8554 35.5023 56.3541 35.0228 55.1933 34.0637C54.0326 33.1046 53.4545 31.8587 53.4545 30.3215C53.4545 28.7171 54.0684 27.4578 55.2919 26.5435C56.5199 25.6069 58.2005 25.1408 60.3427 25.1408C61.1673 25.1408 61.9695 25.2259 62.7493 25.4052C63.5291 25.5621 64.2731 25.7861 64.9902 26.073V24.2714C64.9902 23.0658 64.6316 22.165 63.919 21.56C63.2065 20.9594 62.1802 20.6592 60.8447 20.6592C60.0648 20.6592 59.2492 20.7802 58.4022 21.0267C57.5551 21.2507 56.6095 21.6048 55.5608 22.0978L54.3239 19.5881C55.5967 18.9875 56.7977 18.5528 57.9361 18.2839C59.0699 17.9971 60.1948 17.8492 61.3107 17.8492C63.5157 17.8492 65.2232 18.387 66.4243 19.4581C67.6522 20.5023 68.2662 21.9947 68.2662 23.9352V35.202H64.9902V33.7321C64.2327 34.3326 63.4171 34.7763 62.5477 35.0676C61.6782 35.3589 60.7326 35.5023 59.7063 35.5023ZM56.6633 30.2543C56.6633 31.0789 57.0084 31.7467 57.6985 32.2576C58.4156 32.773 59.3388 33.0284 60.4772 33.0284C61.3645 33.0284 62.1891 32.894 62.951 32.6296C63.7084 32.3607 64.3851 31.9573 64.9902 31.424V28.4841C64.3403 28.1255 63.6636 27.8701 62.951 27.7132C62.234 27.534 61.4541 27.4443 60.6071 27.4443C59.406 27.4443 58.447 27.6998 57.7344 28.2152C57.0218 28.7261 56.6633 29.4073 56.6633 30.2543Z"
                    fill="currentcolor"
                />
                <path
                    d="M78.9055 32.5579C79.7525 32.5579 80.5547 32.3921 81.3121 32.0559C82.0695 31.7019 82.7956 31.1775 83.4857 30.4874L85.489 32.6565C84.6016 33.5483 83.5754 34.2519 82.4146 34.7628C81.2584 35.2558 80.0438 35.4978 78.7711 35.4978C77.5476 35.4978 76.4003 35.2782 75.3292 34.8301C74.2581 34.364 73.3349 33.741 72.555 32.9612C71.7752 32.1769 71.1478 31.2537 70.6817 30.1826C70.2381 29.0936 70.014 27.9239 70.014 26.6735C70.014 25.45 70.2381 24.3027 70.6817 23.2316C71.1478 22.1381 71.7752 21.2014 72.555 20.4216C73.3349 19.6194 74.2581 18.9965 75.3292 18.5528C76.4003 18.0822 77.5476 17.8492 78.7711 17.8492C80.0438 17.8492 81.2808 18.1046 82.4818 18.6155C83.6874 19.1085 84.7316 19.7987 85.6235 20.6905L83.5171 22.9627C82.8717 22.2726 82.1502 21.7482 81.348 21.3942C80.5458 21.0132 79.7077 20.825 78.8383 20.825C77.3011 20.825 75.9969 21.3942 74.9258 22.528C73.8592 23.6663 73.3214 25.0467 73.3214 26.6735C73.3214 28.3451 73.8592 29.7479 74.9258 30.8862C76.0193 32.0022 77.3459 32.5579 78.9055 32.5579Z"
                    fill="currentcolor"
                />
                <path
                    d="M91.1629 36.802L91.8307 35.2648L85.0455 18.1809H88.7249L93.704 31.1866L99.185 18.1809H102.797L94.5734 37.2368C93.7936 39.0428 92.9018 40.3336 91.8979 41.1134C90.894 41.9156 89.6122 42.3189 88.0526 42.3189C87.6986 42.3189 87.3625 42.2965 87.0488 42.2517C86.7619 42.2293 86.5065 42.1845 86.2824 42.1172V39.1773C86.5065 39.2221 86.7261 39.2535 86.9502 39.2759C87.1967 39.2983 87.4745 39.3073 87.7882 39.3073C88.5456 39.3073 89.1999 39.0966 89.7601 38.6754C90.3383 38.272 90.8044 37.6491 91.1629 36.802Z"
                    fill="currentcolor"
                />
            </g>
        </svg>
    );
}
