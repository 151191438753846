import React from "react";
import styled from "styled-components";
import { ISVGProps } from "types";

const SVG = styled.svg`
    & > path {
        stroke-dasharray: 320;
        stroke-dashoffset: 320;
    }

    &.sal-animate > path {
        animation: dash 1.5s ease-out;
        animation-fill-mode: forwards;
    }
`;

export default function YellowCrown(props: ISVGProps) {
    return (
        <SVG
            xmlns="http://www.w3.org/2000/svg"
            width="112"
            height="78"
            viewBox="0 0 112 78"
            fill="none"
            {...props}
            data-sal="dash"
        >
            <path
                d="M85.0002 72H21.0604L6.00018 24.6L33.1086 42.3L53.8918 6L79.4942 37.5L106 13.5L102.085 56.1"
                stroke="#FFCD3F"
                strokeWidth="12"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </SVG>
    );
}
