import styled from "styled-components";

const SeeAllButton = styled.button`
    border: none;
    background: #444;
    padding: 15px 50px;
    color: #fff;
    border-radius: 50px;
    font-weight: bold;
    font-size: 16px;
    margin-top: 20px;

    @media (prefers-color-scheme: dark) {
        border: 3px solid #434343;
        background: none;
    }
`;

export default SeeAllButton;
