import React from "react";
import styled from "styled-components";
import { ISVGProps } from "types";

const DuckyHand = styled.g`
    animation: check 3s linear infinite;
`;

export default function PrivateDucky(props: ISVGProps) {
    return (
        <svg
            width="168"
            height="187"
            viewBox="0 0 168 187"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M32.858 170.917C6.76611 172.362 -3.65545 184.686 31.4888 186.587C66.633 188.489 73.6314 183.24 97.213 182.48C120.795 181.719 149.169 184.077 162.024 182.48C174.88 180.882 165.372 170.917 137.986 170.917C110.601 170.917 51.6472 166.125 51.6472 166.125L32.858 170.917Z"
                fill="#232323"
            />
            <path
                d="M43.2018 155.779C28.2921 169.168 29.1289 179.513 47.766 181.187C66.403 182.86 79.7152 179.057 75.3793 167.418C71.1194 155.703 43.2018 155.779 43.2018 155.779Z"
                fill="#FF814A"
            />
            <path
                d="M55.528 182.86C53.0938 182.86 50.4313 182.708 47.6167 182.48C38.7166 181.719 33.4678 178.981 31.8703 174.416C30.1207 169.244 33.696 162.473 42.2158 154.79C42.444 154.562 42.7483 154.41 43.1286 154.41C44.4979 154.41 72.0351 154.562 76.5993 166.885C78.0446 170.765 77.7404 174.112 75.7625 176.775C72.7197 180.73 65.6453 182.86 55.528 182.86ZM43.7372 157.149C36.4345 163.843 33.1635 169.624 34.4567 173.58C35.5977 177.003 40.238 179.133 47.8449 179.817C50.5835 180.046 53.1698 180.198 55.528 180.198C64.6564 180.198 71.1223 178.448 73.6326 175.177C75.0779 173.275 75.2301 170.917 74.089 167.874C72.1112 162.473 63.8957 159.811 57.4297 158.594C51.3442 157.377 45.4107 157.149 43.7372 157.149Z"
                fill="#232323"
            />
            <path
                d="M150.383 154.334C165.292 167.722 164.456 178.067 145.819 179.741C127.181 181.415 113.869 177.611 118.205 165.972C122.465 154.258 150.383 154.334 150.383 154.334Z"
                fill="#FF814A"
            />
            <path
                d="M138.059 181.415C127.942 181.415 120.867 179.285 117.825 175.329C115.771 172.667 115.542 169.396 116.988 165.44C121.552 153.041 149.241 152.965 150.458 152.965C150.763 152.965 151.143 153.117 151.371 153.345C159.891 161.028 163.466 167.798 161.717 172.971C160.195 177.535 154.87 180.274 145.97 181.034C143.156 181.263 140.493 181.415 138.059 181.415ZM149.85 155.703C148.1 155.703 142.243 155.931 136.081 157.148C129.615 158.442 121.4 161.028 119.422 166.429C118.281 169.472 118.433 171.83 119.878 173.732C123.073 177.916 132.43 179.589 145.666 178.448C153.273 177.763 157.913 175.633 159.054 172.21C160.424 168.179 157.153 162.321 149.85 155.703Z"
                fill="#232323"
            />
            <path
                d="M150.613 151.824C145.212 159.203 137.682 163.843 129.694 166.734C115.469 171.983 100.103 171.602 94.0936 171.222C92.2679 171.07 90.4422 171.146 88.5405 171.298C43.6593 175.254 25.859 158.366 26.2394 128.775C26.3154 124.135 26.7718 119.114 27.7608 113.865C29.7386 102.835 36.2045 91.0444 35.9763 78.4169C34.8352 24.1031 66.6324 11.3994 89.3012 10.943C105.2 10.6387 117.295 14.8986 126.195 22.3535C137.453 31.8622 143.463 46.5436 145.441 63.7354C146.353 71.6466 148.027 79.5579 150.537 87.0888C153.58 96.3693 155.938 104.737 157.231 112.04C160.198 130.296 157.003 143 150.613 151.824Z"
                fill="#FFCD3F"
            />
            <path
                d="M129.694 166.733C115.469 171.982 100.103 171.601 94.0936 171.221C92.2679 171.069 90.4422 171.145 88.5405 171.297C43.6593 175.253 25.859 158.365 26.2394 128.774L74.0872 121.928C108.395 133.186 130.835 147.183 129.694 166.733Z"
                fill="#F7C34F"
            />
            <path
                d="M76.2905 173.2C68.4553 173.2 61.5329 172.439 55.5234 170.918C46.7754 168.712 39.777 164.832 34.7564 159.355C25.628 149.39 22.8135 134.024 26.3887 113.637C27.1494 109.301 28.5187 105.041 30.0401 100.477C32.3222 93.4025 34.7564 86.0238 34.6043 78.4168C34.1478 54.9112 39.6249 37.0349 51.1114 25.168C56.2841 19.8431 62.5979 15.7353 69.8246 13.1489C75.8341 10.9429 82.376 9.8019 89.2984 9.64976C106.794 9.34548 120.715 14.3661 130.604 24.7116C139.428 33.916 144.905 47 146.731 63.5832C147.644 71.4184 149.317 79.2536 151.752 86.7084C155.175 97.1299 157.305 105.117 158.522 111.811C161.717 130.448 158.674 145.206 149.317 155.628C133.115 173.58 102.915 173.124 94.0908 172.591C92.3412 172.515 90.5155 172.515 88.7659 172.667C84.2778 173.048 80.17 173.2 76.2905 173.2ZM89.2223 12.3122C80.7786 12.4643 64.88 14.5943 52.9371 26.9936C42.0591 38.328 36.7342 55.5959 37.1906 78.3407C37.3428 86.4041 34.9085 93.935 32.4743 101.314C31.029 105.726 29.6597 109.986 28.899 114.093C25.4759 133.567 28.0623 148.173 36.6581 157.529C46.2429 167.951 63.6629 172.135 88.3095 169.929C90.2112 169.777 92.113 169.7 94.0147 169.853C102.611 170.385 131.745 170.918 147.111 153.802C155.859 144.065 158.75 130.068 155.631 112.268C154.49 105.65 152.36 97.8146 149.013 87.5452C146.503 79.9382 144.753 71.9509 143.84 63.8875C141.178 40.2298 129.767 12.3122 90.6676 12.3122C90.2873 12.3122 89.7548 12.3122 89.2223 12.3122Z"
                fill="#232323"
            />
            <path
                d="M79.3389 15.0508C81.5449 1.43437 89.304 -1.68449 90.5972 6.68318C90.5972 6.68318 93.5639 0.0651212 101.475 1.89079C109.386 3.71647 102.768 16.8005 88.9236 15.7355"
                fill="#FFCD3F"
            />
            <path
                d="M90.4464 16.8C89.9139 16.8 89.3815 16.8 88.849 16.7239C88.2404 16.6478 87.784 16.1914 87.8601 15.5829C87.9361 14.9743 88.3925 14.5179 89.0011 14.594C97.597 15.2025 103.15 10.1058 103.759 6.22628C103.911 5.00917 103.683 3.33563 101.248 2.80315C94.326 1.28175 91.7396 6.75877 91.5875 6.98698C91.3593 7.4434 90.9028 7.6716 90.4464 7.59553C89.99 7.51946 89.6097 7.1391 89.5336 6.68268C89.1532 4.32452 88.2404 2.95527 87.0994 2.95527C85.1216 2.95527 81.6984 6.68268 80.4052 15.0504C80.3292 15.6589 79.7967 16.0393 79.1881 15.9632C78.5795 15.8871 78.1992 15.2786 78.2753 14.7461C78.9599 10.6383 80.177 7.13912 81.7745 4.62881C83.3719 2.19458 85.2737 0.901402 87.0994 0.901402C88.849 0.901402 90.2182 2.04243 90.9789 4.02024C91.3593 3.56382 91.8157 3.10742 92.3482 2.651C94.0978 1.20567 97.1406 -0.315728 101.629 0.673179C104.595 1.35781 106.193 3.56383 105.813 6.45448C105.204 11.0947 99.4987 16.8 90.4464 16.8Z"
                fill="#232323"
            />
            <path
                d="M36.5081 39.089C65.1103 10.4107 127.335 18.1698 143.614 37.2633C150.08 49.5105 145.516 55.2158 145.516 55.2158C111.437 40.8386 57.6555 42.9685 36.6602 60.2364L35.5953 56.6611C33.9217 50.8798 34.226 44.4899 36.5081 39.089Z"
                fill="#58BF43"
            />
            <path
                d="M36.7376 61.9098C36.5855 61.9098 36.4333 61.9098 36.2812 61.8337C35.9009 61.6816 35.5966 61.3012 35.4444 60.8448L34.3795 57.2695C32.4777 51.0318 32.8581 44.1095 35.2923 38.2521C35.3684 38.0239 35.5205 37.8718 35.6726 37.7196C48.6806 24.6356 70.2083 17.6372 94.7028 18.5501C105.2 18.9304 115.47 20.7561 124.37 23.7989C133.422 26.9177 140.345 31.1016 144.528 35.97C144.604 36.0461 144.681 36.1982 144.757 36.2743C151.755 49.5865 146.734 56.2046 146.506 56.4328C146.126 56.8892 145.593 57.0414 145.137 56.8131C111.134 42.512 57.8089 44.8702 37.4223 61.6055C37.194 61.8337 36.9658 61.9098 36.7376 61.9098ZM37.5744 40.2299C35.5966 45.0984 35.3684 50.8036 36.8898 55.9764L37.4223 57.6499C47.7677 49.9668 65.3398 45.8591 86.1069 44.9463C107.254 44.0334 128.706 46.5437 145.061 53.2379C145.822 51.4883 146.963 46.7719 142.627 38.4803C134.715 29.428 115.622 21.8971 94.7788 21.2125C71.0451 20.2997 50.1259 27.8306 37.5744 40.2299Z"
                fill="#232323"
            />
            <path
                d="M65.5654 34.2199C68.1518 33.6874 68.4561 33.6113 68.4561 33.6113L65.5654 34.2199Z"
                fill="#6B6B6B"
            />
            <path
                d="M65.5662 35.2839C65.1858 35.2839 64.8055 34.9036 64.7294 34.3711C64.6534 33.7625 64.9576 33.23 65.4141 33.154C67.3158 32.7736 68.2286 32.5454 68.3808 32.5454C68.8372 32.4693 69.2175 32.9258 69.2936 33.5343C69.2936 34.1429 68.9893 34.6754 68.5329 34.6754C68.5329 34.6754 68.0765 34.7514 65.7183 35.2839C65.6423 35.2839 65.5662 35.2839 65.5662 35.2839Z"
                fill="#232323"
            />
            <path
                d="M34.9854 46.9225C41.147 41.2933 51.2643 37.718 58.7952 35.7402L34.9854 46.9225Z"
                fill="#6B6B6B"
            />
            <path
                d="M34.9849 48.0644C34.7567 48.0644 34.4524 47.9123 34.3002 47.608C34.072 47.1516 34.1481 46.4669 34.5285 46.0866C40.8422 40.3814 51.1117 36.73 58.6426 34.6761C59.099 34.524 59.5554 34.9043 59.6315 35.5129C59.7075 36.1215 59.4033 36.6539 59.0229 36.8061C51.6441 38.7839 41.6029 42.3592 35.5174 47.8362C35.3652 47.9883 35.2131 48.0644 34.9849 48.0644Z"
                fill="#232323"
            />
            <path
                d="M123.687 38.0215C136.314 38.8583 145.899 45.7045 145.899 45.7045L123.687 38.0215Z"
                fill="#6B6B6B"
            />
            <path
                d="M145.824 46.7705C145.671 46.7705 145.519 46.6944 145.443 46.6184C145.367 46.5423 135.858 39.9242 123.611 39.0875C123.155 39.0875 122.774 38.555 122.851 37.9464C122.851 37.3379 123.231 36.8814 123.687 36.9575C136.315 37.7943 145.824 44.4884 146.204 44.7927C146.584 45.097 146.736 45.7055 146.508 46.238C146.432 46.6184 146.128 46.7705 145.824 46.7705Z"
                fill="#232323"
            />
            <path
                d="M28.0671 112.038C15.5156 116.297 7.37614 94.0851 19.243 83.2071C31.1099 72.3292 40.999 71.7967 47.3888 77.2737C53.7787 82.7507 52.9419 96.2151 46.3999 102.453"
                fill="#FFCD3F"
            />
            <path
                d="M24.8743 113.94C20.9948 113.94 17.4195 111.658 14.9092 107.55C12.7792 103.975 11.7903 99.3346 12.1707 94.9226C12.475 91.4233 13.7681 86.4028 18.2563 82.2189C24.1136 76.818 29.7428 73.6991 35.0677 72.7863C40.0122 71.9495 44.5764 73.1666 48.1517 76.2855C51.1184 78.7958 52.7919 82.9796 52.944 87.9242C53.0962 93.9337 50.8902 99.8671 47.1628 103.518C46.6303 104.051 45.7935 104.051 45.261 103.518C44.7285 102.986 44.7285 102.149 45.261 101.617C48.4559 98.4978 50.3577 93.3251 50.2055 88.0763C50.0534 83.9685 48.6841 80.3932 46.326 78.4154C39.784 72.7863 30.1992 74.9162 20.0059 84.2728C13.5399 90.2062 13.4639 100.095 17.1152 106.181C18.3323 108.235 21.7555 112.799 27.4607 110.821C28.1453 110.593 28.906 110.973 29.1342 111.658C29.3624 112.343 28.9821 113.103 28.2975 113.331C27.2325 113.712 26.0154 113.94 24.8743 113.94Z"
                fill="#232323"
            />
            <path
                d="M74.4231 101.804L7.5625 95.3379L2.17253 151.068L69.0331 157.534L74.4231 101.804Z"
                fill="#F4F4F4"
            />
            <path
                d="M68.9859 158.897C68.9099 158.897 68.9099 158.897 68.8338 158.897L1.96848 152.431C1.20779 152.355 0.675298 151.671 0.751368 150.986L6.15232 95.2269C6.15232 94.8465 6.38053 94.5422 6.60874 94.314C6.91301 94.0858 7.2173 94.0098 7.59764 94.0098L74.4629 100.476C75.2236 100.552 75.7561 101.236 75.6801 101.921L70.2791 157.68C70.2791 158.06 70.0509 158.365 69.8227 158.593C69.5945 158.745 69.2902 158.897 68.9859 158.897ZM3.64201 149.845L67.8449 156.083L72.9415 102.986L8.73869 96.7483L3.64201 149.845Z"
                fill="#232323"
            />
            <path
                d="M72.9024 107.067L7.93478 100.783L4.2365 139.022L69.2041 145.305L72.9024 107.067Z"
                fill="#58BF43"
            />
            <path
                d="M69.2204 145.89L4.18083 139.576C4.02869 139.576 3.87654 139.5 3.7244 139.348C3.64833 139.196 3.57227 139.043 3.57227 138.891L7.29968 100.628C7.29968 100.324 7.60396 100.02 7.98431 100.096L72.9479 106.41C73.1 106.41 73.2521 106.486 73.4043 106.638C73.4803 106.79 73.5564 106.942 73.5564 107.094L69.829 145.357C69.829 145.662 69.6008 145.89 69.2204 145.89ZM4.94152 138.435L68.688 144.597L72.2632 107.551L8.5168 101.389L4.94152 138.435Z"
                fill="#232323"
            />
            <DuckyHand>
                <path
                    d="M90.7472 84.7299C68.3827 80.2418 55.9073 85.1103 50.126 90.8155C45.1814 95.6839 40.9215 112.039 76.5983 122.46"
                    fill="#FFCD3F"
                />
                <path
                    d="M76.5223 123.83C76.3701 123.83 76.2941 123.83 76.1419 123.754C53.9296 117.212 47.4637 108.54 45.8662 102.378C44.4969 96.8252 46.7029 92.1849 49.1372 89.8267C51.5714 87.3925 56.1356 84.1976 63.8947 82.6001C71.5017 81.0027 80.554 81.3069 90.9755 83.3608C91.7362 83.513 92.1927 84.1976 92.0405 84.9583C91.8884 85.719 91.2037 86.1754 90.4431 86.0233C80.4018 84.0454 71.6538 83.7412 64.4272 85.2626C57.2766 86.7079 53.1689 89.5985 51.0389 91.8046C49.2133 93.6302 47.3876 97.3576 48.5286 101.77C49.3654 105.193 51.7996 108.464 55.6792 111.431C60.5476 115.234 67.6982 118.505 76.9026 121.167C77.5873 121.396 78.0437 122.156 77.8155 122.841C77.6633 123.45 77.1308 123.83 76.5223 123.83Z"
                    fill="#232323"
                />
            </DuckyHand>
            <path
                d="M105.275 34.2965L97.2121 34.1443C96.8317 34.1443 96.6035 33.764 96.6035 33.3076C96.6035 32.8512 96.9078 32.5469 97.2121 32.5469L105.275 32.699C105.656 32.699 105.884 33.0794 105.884 33.5358C105.884 33.9161 105.656 34.2965 105.275 34.2965Z"
                fill="#232323"
            />
            <path
                d="M78.2666 51.7169C88.3078 45.4792 93.3284 45.0227 103.75 51.5647C106.184 53.0861 105.423 55.5204 103.37 56.3571C98.425 58.411 84.2761 58.7914 78.6469 56.8896C75.9845 56.0529 75.452 53.3904 78.2666 51.7169Z"
                fill="#FF814A"
            />
            <path
                d="M89.5258 59.4755C85.1137 59.4755 80.7778 59.0951 78.1153 58.1823C76.3657 57.5737 75.2247 56.3566 74.9965 54.8352C74.7682 53.2377 75.6811 51.6403 77.4307 50.5753C87.548 44.2615 93.1011 43.3487 104.359 50.4232C105.805 51.336 106.641 52.7053 106.489 54.1506C106.337 55.5959 105.348 56.8891 103.751 57.5737C100.86 58.8669 95.0789 59.4755 89.5258 59.4755ZM91.0472 48.1411C87.3198 48.1411 83.8206 49.8146 78.9521 52.8574C78.1153 53.3899 77.6589 53.9985 77.735 54.5309C77.811 54.9874 78.2675 55.4438 79.0282 55.672C84.353 57.4216 98.1217 57.1173 102.838 55.1395C103.447 54.9113 103.903 54.4549 103.903 53.9984C103.979 53.466 103.37 52.9335 102.99 52.7813C98.1217 49.5864 94.4703 48.1411 91.0472 48.1411Z"
                fill="#232323"
            />
            <path
                d="M23.5023 87.9248C23.1219 87.9248 22.7416 87.7727 22.5134 87.5445C21.9809 87.012 21.9048 86.0992 22.5134 85.4906C22.5894 85.3385 25.328 82.676 30.4246 80.47C31.1853 80.1657 32.0221 80.47 32.3264 81.2307C32.6306 81.9914 32.3264 82.8282 31.5657 83.1324C27.0776 85.1102 24.6433 87.5445 24.5673 87.5445C24.263 87.7727 23.8826 87.9248 23.5023 87.9248Z"
                fill="#FFF4C5"
            />
            <path
                d="M35.062 81.2305C34.4534 81.2305 33.9209 80.8502 33.6927 80.2416C33.4645 79.4809 33.8449 78.6442 34.6056 78.416C34.6816 78.416 34.6816 78.416 34.7577 78.3399C34.9098 78.2638 35.138 78.1878 35.3663 78.1117C36.127 77.8835 36.9637 78.3399 37.1159 79.1006C37.3441 79.8613 36.8877 80.698 36.127 80.8502C36.0509 80.8502 35.9748 80.8502 35.9748 80.9262C35.8227 81.0023 35.7466 81.0023 35.5945 81.0784C35.3663 81.2305 35.2141 81.2305 35.062 81.2305Z"
                fill="#FFF4C5"
            />
            <path
                d="M27.5301 41.5233C27.2258 41.5233 26.8455 41.4472 26.5412 41.295L18.8582 37.1112C17.8693 36.5787 17.4889 35.2856 18.0214 34.2966C18.5539 33.3077 19.8471 32.9274 20.836 33.4599L28.519 37.6437C29.5079 38.1762 29.8883 39.4694 29.3558 40.4583C28.9754 41.1429 28.2908 41.5233 27.5301 41.5233Z"
                fill="#58BF43"
            />
            <path
                d="M24.9474 49.1294C24.8713 49.1294 24.7953 49.1294 24.7953 49.1294L19.014 48.5969C17.8729 48.5209 17.0362 47.532 17.1122 46.3909C17.1883 45.2499 18.1772 44.4131 19.3182 44.4892L25.0995 45.0217C26.2406 45.0977 27.0774 46.0866 27.0013 47.2277C26.9252 48.2927 26.0124 49.1294 24.9474 49.1294Z"
                fill="#58BF43"
            />
            <path
                d="M66.4805 60.9968C65.872 61.6814 64.807 61.7575 64.1224 61.1489L61.9163 59.2472C61.2317 58.6386 61.1556 57.5736 61.7642 56.889C62.3728 56.2044 63.4377 56.1283 64.1224 56.7369L66.3284 58.6386C67.013 59.2472 67.0891 60.3122 66.4805 60.9968Z"
                fill="#F2BA3D"
            />
            <path
                d="M60.5461 62.2134C59.9375 62.8981 58.8725 62.9741 58.1879 62.3656L55.9819 60.4638C55.2973 59.8553 55.2212 58.7903 55.8297 58.1057C56.4383 57.421 57.5033 57.345 58.1879 57.9535L60.3939 59.8553C61.0786 60.4638 61.1546 61.5288 60.5461 62.2134Z"
                fill="#F2BA3D"
            />
            <path
                d="M118.814 59.5509C118.054 59.0945 117.749 58.1056 118.206 57.2688L119.651 54.7585C120.107 53.9978 121.096 53.6936 121.933 54.15C122.694 54.6064 122.998 55.5953 122.542 56.4321L121.096 58.9424C120.64 59.7031 119.651 60.0073 118.814 59.5509Z"
                fill="#F2BA3D"
            />
            <path
                d="M112.195 59.7795C111.434 59.323 111.13 58.3341 111.587 57.4974L113.032 54.9871C113.488 54.2264 114.477 53.9221 115.314 54.3785C116.075 54.8349 116.379 55.8238 115.923 56.6606L114.477 59.1709C114.021 59.9316 113.032 60.2359 112.195 59.7795Z"
                fill="#F2BA3D"
            />
            <path
                d="M124.902 168.634C141.257 169.243 156.091 160.038 156.091 160.038L149.777 153.496L138.443 163.385L124.902 168.634Z"
                fill="#232323"
            />
            <path
                d="M35.9005 165.364C46.6263 173.199 74.9242 174.036 74.9242 174.036L75.3806 172.363L50.8101 169.244L38.639 161.637L35.9005 165.364Z"
                fill="#232323"
            />
            <path
                d="M66.8604 158.136C66.8604 158.136 46.1694 156.691 39.8556 161.712C34.3786 165.591 38.2582 161.179 38.2582 161.179L34.1504 155.018L66.8604 158.136Z"
                fill="#232323"
            />
            <path
                d="M50.6572 81.6113C52.0265 85.3387 55.5257 85.3387 58.6445 84.7302C61.7634 84.1216 52.8633 88.838 52.8633 88.838L50.6572 81.6113Z"
                fill="#232323"
            />
            <path
                d="M44.2356 120.526L44.4929 117.537C44.7769 114.237 42.3308 111.329 39.0294 111.045C35.7286 110.761 32.8211 113.207 32.537 116.509L32.2798 119.498C30.6289 119.355 29.1757 120.578 29.0337 122.229L28.2621 131.196C28.12 132.847 29.3429 134.3 30.9938 134.442L42.9496 135.471C44.6005 135.613 46.0537 134.39 46.1958 132.739L46.9674 123.772C47.1095 122.122 45.8865 120.668 44.2356 120.526ZM35.526 116.766C35.6681 115.115 37.1216 113.892 38.7722 114.034C40.4231 114.176 41.6459 115.63 41.5039 117.28L41.2467 120.269L35.2688 119.755L35.526 116.766Z"
                fill="#232323"
            />
        </svg>
    );
}
