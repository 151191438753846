import React from "react";
import { ISVGProps } from "types";

export default function NextBtn(props: ISVGProps) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="53"
            height="53"
            viewBox="0 0 53 53"
            fill="none"
            {...props}
        >
            <path
                d="M26.5 0C41.1159 0 53 11.8242 53 26.3664C53 40.9087 41.1159 52.7328 26.5 52.7328C11.8841 52.7328 0 40.9087 0 26.3664C0 11.8242 11.8841 0 26.5 0ZM26.5 3.2958C13.6743 3.2958 3.3125 13.6053 3.3125 26.3664C3.3125 39.1275 13.6743 49.437 26.5 49.437C39.3257 49.437 49.6875 39.1275 49.6875 26.3664C49.6875 13.6053 39.3257 3.2958 26.5 3.2958ZM23.2134 15.6293C23.646 15.6463 24.0547 15.8312 24.3521 16.1443L33.4614 25.2077C33.768 25.5162 33.9399 25.9326 33.9399 26.3664C33.9399 26.8002 33.768 27.2166 33.4614 27.5251L24.3521 36.5885C24.2062 36.7686 24.0239 36.9162 23.8169 37.0215C23.61 37.1269 23.3831 37.1878 23.151 37.2001C22.9188 37.2125 22.6867 37.1762 22.4696 37.0934C22.2525 37.0106 22.0554 36.8833 21.891 36.7198C21.7267 36.5563 21.5987 36.3601 21.5155 36.1441C21.4324 35.9282 21.3958 35.6972 21.4082 35.4662C21.4207 35.2353 21.4819 35.0095 21.5878 34.8036C21.6937 34.5977 21.8419 34.4163 22.0229 34.2712L29.9678 26.3664L22.0229 18.4616C21.7834 18.2311 21.6192 17.934 21.552 17.6092C21.4848 17.2844 21.5177 16.947 21.6462 16.6411C21.7748 16.3351 21.9932 16.0749 22.2728 15.8944C22.5524 15.7139 22.8802 15.6215 23.2134 15.6293Z"
                fill="currentcolor"
            />
        </svg>
    );
}
